import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import { useInView } from 'react-intersection-observer';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import {
  StyledJobSliderWrapper,
  StyledJobSliderContainer,
  StyledJobSliderHeading,
  StyledJobSliderSubHeading,
  StyledJobSliderArrowWrapper,
  SliderHeaderContainer,
} from './styles';
import theme from '../Layout/theme';

import WindowWidthContext from '../../context/WindowWidthContext';

import ArrowButton from '../ArrowButton';
import JobSlide from '../JobSlide';
import { useStaticQuery, graphql } from 'gatsby';
import { StyledCarouselHeadingContainer } from '../Carousel/styles';
import { jobData } from '../../data/culturePageData';

export default function JobSlider(props) {
  const { data } = useStaticQuery(graphql`
    query {
      data: contentfulJobSlider {
        heading
        subHeading {
          subHeading
        }
        slides {
          jobTitle
          roleType
          roleLocation
          pdf {
            file {
              url
            }
          }
        }
      }
    }
  `);

  const [disablePrevArrow, setDisablePrevArrow] = React.useState(true);
  const [isCulturePage, setIsCulturePage] = useState(false);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setIsCulturePage(window.location.href.includes('/culture'));
    }
  }, []);

  /**
   * START: Slick slide
   */
  let slideChangeTimeout;
  let sliderRef;

  const settings = {
    // Default settings
    easing: 'ease-in-out',
    variableWidth: true,
    swipeToSlide: true,
    infinite: false,
    arrows: false,
    dots: false,
    beforeChange: (current, next) => {
      // clear the timeout if one exists
      clearTimeout(slideChangeTimeout);
    },
    afterChange: current => {
      if (current >= data.slides.length - 1) {
        slideChangeTimeout = setTimeout(() => {
          sliderRef.slickGoTo(data.slides.length - 2);
        }, 20);
      }
      setDisablePrevArrow(current > 0 ? false : true);
    },
    responsive: [
      {
        // Laptop
        breakpoint: theme.minWidthDesktop,
        settings: {
          afterChange: current => {
            setDisablePrevArrow(current > 0 ? false : true);
          },
        },
      },
      {
        // Tablet
        breakpoint: theme.minWidthLaptop,
        settings: {
          afterChange: current => {
            setDisablePrevArrow(current > 0 ? false : true);
          },
        },
      },
      {
        // Mobile
        breakpoint: theme.minWidthTablet,
        settings: {
          afterChange: current => {
            setDisablePrevArrow(current > 0 ? false : true);
          },
        },
      },
    ],
  };

  function decrementSlide() {
    sliderRef.slickPrev();
  }

  function incrementSlide(width) {
    if (
      width > 1000 &&
      sliderRef.innerSlider.state.currentSlide >=
        sliderRef.innerSlider.state.slideCount - 2
    ) {
      // Laptop width and greater scroll back to start when last 2 slides show
      sliderRef.slickGoTo(0);
    } else if (
      width > theme.minWidthTablet &&
      sliderRef.innerSlider.state.currentSlide >=
        sliderRef.innerSlider.state.slideCount - 2
    ) {
      // On tablet scroll back to start when last 2 slides show
      sliderRef.slickGoTo(0);
    } else if (
      sliderRef.innerSlider.state.currentSlide >=
      sliderRef.innerSlider.state.slideCount - 1
    ) {
      // On mobile scroll back to start when last slide show
      sliderRef.slickGoTo(0);
    } else {
      // Else just handle regular scroll forward
      sliderRef.slickNext();
    }
  }
  // END: Slick slide

  /**
   * START
   * To tell the Nav what page we are on
   */
  // const [ref, inView] = useInView({
  //   threshold: 0.85,
  // });

  // React.useEffect(() => {
  //   if (inView === true) {
  //     props.setActiveView(inView, 'jobs/footer');
  //     window.location.hash = 'careers';
  //   }
  // }, [inView]);
  // END

  /**
   * START
   * handle carousel reset
   */
  // const [carouselRef, carouselIsInView] = useInView({ threshold: 0.05 });

  // React.useEffect(() => {
  //   if (!carouselIsInView) {
  //     sliderRef.slickGoTo(0);
  //   }
  // }, [carouselIsInView]);
  // END

  /**
   * START
   * Disable vertical scroll while horizontal scrolling on carousel
   */
  // const [firstClientX, setFirstClientX] = React.useState(0);
  // const [firstClientY, setFirstClientY] = React.useState(0);

  // function handleTouchStart(e) {
  //   setFirstClientX(e.touches[0].clientX);
  //   setFirstClientY(e.touches[0].clientY);
  // }

  // function handleTouchMove(e) {
  //   const minValue = 5; // threshold

  //   const clientX = e.touches[0].clientX - firstClientX;
  //   const clientY = e.touches[0].clientY - firstClientY;

  //   if (Math.abs(clientX) > minValue) {
  //     e.preventDefault();
  //     setFirstClientX(clientX);
  //     setFirstClientY(firstClientY);

  //     e.returnValue = false;
  //     return false;
  //   }
  // }
  // END

  function handleWheelEvent(e, width, height) {
    if (e.deltaX > 15) return incrementSlide(width, height);
    if (e.deltaX < -15) return decrementSlide();
  }

  return (
    <WindowWidthContext.Consumer>
      {({ width, height }) => {
        return (
          <StyledJobSliderWrapper>
            <StyledJobSliderContainer
              onWheel={e => handleWheelEvent(e, width, height)}
            >
              <SliderHeaderContainer>
                <StyledJobSliderHeading>We're hiring...</StyledJobSliderHeading>
                <StyledJobSliderSubHeading>
                  {isCulturePage
                    ? `The incredibly talented people at Cherry are why we continue
                  to create award-winning work and have great fun doing it. The
                  Cherry family tree is still growing. If you want to be part of
                  the team, get in touch.`
                    : `The incredibly talented people at Cherry are why we continue to create award-winning work and have great fun doing it. The Cherry family tree is still growing. If you want to be part of the team, get in touch.`}
                </StyledJobSliderSubHeading>
              </SliderHeaderContainer>
              <Slider
                ref={thisRef => (sliderRef = thisRef)}
                // onTouchStart={handleTouchStart}
                // onTouchMove={handleTouchMove}
                {...settings}
              >
                {/* {jobData.map((el, index) => {
                const { link, heading } = el;

                return (
                  <JobSlide
                    key={index}
                    link={link}
                    heading={heading}
                    // slide={slide}
                    width={width}
                  />
                );
              })} */}
                {data.slides.map(slide => (
                  <JobSlide key={slide.jobTitle} slide={slide} width={width} />
                ))}
              </Slider>

              <StyledJobSliderArrowWrapper>
                <ArrowButton
                  disablePrevArrow={disablePrevArrow}
                  onClick={() => decrementSlide()}
                  arrowDirection="left"
                  width={width}
                  color="white"
                />
                <ArrowButton
                  onClick={() => incrementSlide(width)}
                  arrowDirection="right"
                  width={width}
                  color="white"
                />
              </StyledJobSliderArrowWrapper>
            </StyledJobSliderContainer>
          </StyledJobSliderWrapper>
        );
      }}
    </WindowWidthContext.Consumer>
  );
}

JobSlider.propTypes = {
  setActiveView: PropTypes.func,
};
