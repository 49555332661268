import styled from 'styled-components';

export const StyledJobSliderWrapper = styled.div`
  background: ${({ theme }) => theme.staticLinearGradient};
  /* height: 75vh;*/
  /* min-height: 75vh; */
  padding: 50px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const StyledJobSliderContainer = styled.section`
  color: ${props => props.theme.white};
  /* grid-template-columns: 100%; */
  /* align-items: center; */
  /* display: grid;
  height: 100%; */
  display: flex;
  flex-direction: column;

  .slick-list {
    padding: 15px 0;
  }

  .slick-track {
    align-items: stretch;
    flex-direction: row;
    flex-wrap: nowrap;
    display: flex;
  }

  /* .slick-slider,
  .slick-list,
  .slick-track {
    height: 100%;
  } */

  .slick-slide {
    align-self: center;
    /* -ms-grid-column-align: center; */
    display: flex;
    height: auto;
  }

  .slick-track {
    :first-child {
      /* Allows for positioning first slide */
      margin-left: 12px;

      @media screen and (min-width: 768px) {
        margin-left: calc(50% - 272px);
      }

      @media screen and (min-width: ${props => props.theme.minWidthLaptop}px) {
        margin-left: 120px;
      }
    }
  }
`;

export const StyledJobSliderHeading = styled.h2`
  /* padding: 0 19px 0 12px; */
  grid-area: 1/1/2/2;
  font-weight: 100;
  font-size: 32px;
  margin-bottom: 10px;

  /* -ms-grid-row: 1;
  -ms-grid-row-span: 1;
  -ms-grid-column: 1;
  -ms-grid-column-span: 1; */

  /* @media screen and (min-width: ${props => props.theme.minWidthTablet}px) {
    padding-left: 11%;
  } */

  @media screen and (min-width: ${props => props.theme.minWidthLaptop}px) {
    /* padding-left: 7.5%; */
    align-self: end;
    font-size: 48px;
    margin: 26px 0;
  }

  @media screen and (min-width: ${props => props.theme.minWidthDesktop}px) {
    font-size: 60px;
  }

  /* @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    padding-top: 4%;
  } */
`;

export const StyledJobSliderSubHeading = styled.p`
  /* padding: 0 19px 0 12px; */
  margin-bottom: 30px;
  /* font-family: ${props => props.theme.bodyFont}; */
  font-weight: 300;
  font-size: 14px;

  @media screen and (min-width: ${props => props.theme.minWidthLaptop}px) {
    /* padding: 0 0 0 7.5%; */
    align-self: end;
    font-size: 16px;
    /* width: 52%; */
  }

  @media screen and (min-width: ${props => props.theme.minWidthDesktop}px) {
    font-size: 1.6em;
  }
`;

export const StyledJobSliderArrowWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
`;

export const SliderHeaderContainer = styled.div`
  /* padding-left: 12px; */
  max-width: 284px;
  margin-left: 16px;

  @media screen and (min-width: ${props => props.theme.minWidthTablet}px) {
    max-width: 544px;
    /* max-width: 63vw;
    padding-left: 11%; */
  }
  @media screen and (min-width: 768px) {
    margin-inline: auto;
  }
  @media screen and (min-width: ${props => props.theme.minWidthLaptop}px) {
    /* to align with the location card copy, which is half screen size with 15% padding */
    /* padding-left: 7.5%;
    max-width: 54vw; */
    max-width: 680px;
    margin-inline: 0px;
    padding-left: 120px;
  }

  @media screen and (min-width: ${props => props.theme.minWidthDesktop}px) {
    max-width: 1000px;
  }
`;
